<template>
    <div>
        <ExternalRecipientCategories />
    </div>
</template>

<script>
import ExternalRecipientCategories from '@/components/settings/externalRecipientCategories/index.vue';

export default {
    name: 'SettingsExternalRecipientCategories',
    components: {
        ExternalRecipientCategories,
    },
};
</script>
